import { Component, OnInit } from '@angular/core';
import { ServicioService } from 'src/app/servicio.service';

@Component({
  selector: 'app-home-tarjetas-origamis',
  templateUrl: './home-tarjetas-origamis.component.html',
  styleUrls: ['./home-tarjetas-origamis.component.css']
})
export class HomeTarjetasOrigamisComponent implements OnInit {
  datos;
  constructor(private servicio: ServicioService) {
    this.getOrigamis();
  }

  getOrigamis() {
    this.servicio.origamis().subscribe(
      data => {
        this.datos = data
      }, error => {
        console.log(error)
      }
    )
  }

  ngOnInit(): void {
  }

}
