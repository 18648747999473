<div class="home-tarjetas-origamis">
  <nz-row>
    <nz-col nzXs="24">
      <nz-row nzType="flex" nzJustify="center" nzGutter="16">
        <nz-col *ngFor="let obj of datos" nzXs="22" nzSm="11" nzXl="7" nzXXl="5" style="margin-bottom: 5%;">
          <nz-card >
            <nz-row nzType="flex" nzJustify="center">
              <nz-col nzXs="22">
                <img  [src]="obj.imprimir" [alt]="obj.modelo"/>
              </nz-col>
              <nz-col nzXs="22">
                <h2>{{obj.modelo}}</h2>
              </nz-col>
              <nz-col nzXs="22">
                <a [href]="obj.para_pintar"  target="a_blank" download="instrucciones.pdf">INSTRUCCIONES</a>
              </nz-col>
            </nz-row>
          </nz-card>
        </nz-col>
      </nz-row>
    </nz-col>
  </nz-row>
</div>
