import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-banner-principal',
  templateUrl: './home-banner-principal.component.html',
  styleUrls: ['./home-banner-principal.component.css']
})
export class HomeBannerPrincipalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
