<div class="home-seccion-texto">
  <nz-row nzType="flex" nzJustify="center">
    <nz-col nzXs="22" nzSm="16" nzXl="12" nzXXl="9">
      <p>
        El origami es un arte que llegó a Japón en el siglo VI y se integró en la tradición japonesa.
        Es un tipo de papiroflexia que incluye ciertas restricciones, (por ejemplo no admite cortes en el papel y se parte desde ciertas bases),
        con lo que el origami es papiroflexia, pero no toda la papiroflexia es origami.
      </p>
      <nz-divider></nz-divider>
      <p>
        La particularidad de esta tecnica es la transformación del papel en formas de distintos tamaños partiendo de una base inicial cuadrada o rectangular que pueden ir desde sencillos modelos hasta plegados de gran complejidad. En el origami se modela el medio que nos rodea y en el cual vivimos: Fauna y flora de todos los continentes, la vida urbana, herramientas de nuestra cotidianidad, animales mitológicos y un sinfín de otras figuras.
      </p>
      <nz-divider></nz-divider>
      <p>
        Ponemos a disposición algunas plantillas para que puedas pasar el tiempo practicando
        el hermoso arte del ORIGAMI, sigue las instrucciones con calma y un poco de paciencia
        obtendrás un resultado muy bello.
      </p>
    </nz-col>
  </nz-row>
</div>
