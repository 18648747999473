<nz-row>
  <nz-col nzXs="24">
    <div class="home-banner-principal">
      <iframe
        src="https://www.youtube.com/embed/x2GSYKJZE30?autoplay=0"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
  </div>
  </nz-col>
</nz-row>
